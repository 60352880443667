<!-- InfoModal.vue -->
<template>
    <div v-if="isOpen" class="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50 z-50">
      <div class="bg-white p-6 rounded-lg shadow-xl max-w-sm w-full">
        <h2 class="font-bold text-lg mb-4">Prerequisites</h2>
        <p>{{ writeUp }}</p>
        <div class="flex justify-end gap-2 mt-6 text-sm">
          <button @click="closeModal" class="bg-gray-200 px-4 py-2 rounded-[99px] hover:bg-gray-300">
            Close
          </button>
          <button @click="closeModal" class="bg-primary-orange text-primary-purple px-4 py-2 rounded-[99px] hover:bg-primary-orange">
            Ok
          </button>
        </div>
      </div>
    </div>
  </template>
  
  <script setup>
  import { ref } from 'vue';
  
  const props = defineProps({
    isOpen: {
      type: Boolean,
      required: true,
    },
    writeUp:{
        type:String,
        required: true,
    }
  });
  
  const emit = defineEmits(['update:isOpen']);
  
  const closeModal = () => {
    emit('update:isOpen', false);
  };
  </script>
  
  <style scoped>
  /* Modal Styles (optional) */
  </style>
  