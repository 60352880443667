<template>
  <section class="min-h-screen mb-10">
    <Navbar class="flex items-center justify-center" />
    <div
      class="md:mt-[200px] mt-[110px] max-w-[95vw] xl:max-w-[1280px] mx-auto"
    >
      <h1 class="title sm:!text-5xl text-lg mb-4">Popular Courses</h1>
      <div
        class="content sm:w-[460px] w-full text-wrap pr-6 mb-[52px] text-[#5B5B5B]"
      >
        These are the most popular courses among CAMLDS Courses learners
        worldwide in year 2024
      </div>

      <div
        class="h-[60px] lg:flex hidden bg-[#F2EBF8] rounded-md justify-start items-center text-[#5B5B5B] mb-8 max-w-[95vw] sm:min-w-[723px] xl:max-w-[1280px]"
      >
        <div
          class="px-14 py-4 cursor-pointer"
          :class="{
            'font-bold border-b-2  border-primary-purple':
              selectedCategory === 'All',
          }"
          @click="filterCourses('All')"
        >
          All
        </div>
       
        <div
        v-for="(category,index) in categories" :key="index"
          class="px-6 py-4 cursor-pointer"
          :class="{
            'font-bold border-b-2  border-primary-purple':
              selectedCategory == category,
          }"
          @click="filterCourses(category)"
        >
         {{category}}
        </div>
        <div
        class="px-14 py-4 cursor-pointer"
        :class="{
          'font-bold border-b-2  border-primary-purple':
            selectedCategory === 'Mini',
        }"
        @click="filterCourses('Mini')"
      >
        Micro Courses
      </div>
      </div>

      <div class="lg:hidden mb-8 max-w-[95vw] sm:max-w-[754px] xl:max-w-[1280px]"
      >
        <button
          @click="showDropdown = !showDropdown"
          class="w-full h-[54px] bg-[#F2EBF8] rounded-md text-[#5B5B5B] px-4 py-2 cursor-pointer flex items-center justify-between"
        >
          <span>{{ selectedCategory }}</span>
          <i
            :class="{
              'pi pi-chevron-down': !showDropdown,
              'pi pi-chevron-up': showDropdown,
            }"
          ></i>
        </button>
        <transition name="dropdown">
          <div
            v-if="showDropdown"
            class="w-full bg-[#F2EBF8] shadow-lg rounded-md mt-2"
          >
            <div
              class="px-4 py-2 cursor-pointer hover:bg-[#e2d6f1]"
              :class="{ 'font-bold': selectedCategory === 'All' }"
              @click="filterCourses('All')"
            >
              All
            </div>
            <div
            v-for="(category, index) in categories" :key="index"
              class="px-4 py-2 cursor-pointer hover:bg-[#e2d6f1]"
              :class="{ 'font-bold': selectedCategory == category }"
              @click="filterCourses(category)"
            >
              {{category}}
            </div>
            <div
            class="px-4 py-2 cursor-pointer hover:bg-[#e2d6f1]"
            :class="{ 'font-bold': selectedCategory === 'Mini' }"
            @click="filterCourses('Mini')"
          >
            Micro Courses
          </div>
          </div>
        </transition>
      </div>

      <div v-if="!isLoading"
        class="mt-8 h-full flex lg:flex-wrap gap-2 flex-nowrap lg:overflow-x-hidden overflow-x-auto !mx-auto justify-start text-center items-center"
      >
       
        <a v-if="selectedCategory !=='Mini'" v-for="course in filteredCourses" :key="course.title" class="course-item !min-w-[280px] block"
          :href="`/course/${course.id}`"
        >
          <img
            :src="course.image"
            :alt="course.title"
            class="w-full h-48 rounded-t-lg object-cover"
          />
          <div class="mt-4 px-3">
            <h3 class="text-base font-semibold h-[60px] text-left">
              {{ course.title }}
            </h3>
            <div class="flex justify-start gap-4 items-center">
              <p class="text-gray-500 mt-2">
                <i class="pi pi-clock" style="font-size: 0.75rem"></i>
                {{ course.duration }}
              </p>
              <p class="text-gray-500 mt-2 flex justify-start items-center">
                <LevelBarIconComp :type="course.level" /> {{ course.level }}
              </p>
            </div>
            <p class="text-gray-500 mt-2 text-left">
              <i class="pi pi-briefcase" style="font-size: 0.75rem"></i>
              {{ course.projects }} Projects
            </p>
            <p class="text-primary-orange mt-2 text-left">
              <i class="pi pi-star-fill" style="font-size: 0.75rem"></i>
              <i class="pi pi-star-fill" style="font-size: 0.75rem"></i>
              <i class="pi pi-star-fill" style="font-size: 0.75rem"></i>
              <i class="pi pi-star-fill" style="font-size: 0.75rem"></i>
              <i class="pi pi-star-half-fill" style="font-size: 0.75rem"></i>
              
            </p>
            <hr />
            <a :href="`/course/${course.id}`" class="flex justify-end items-center">
              <div
                class="flex justify-start items-center gap-2 py-2 px-4 w-fit my-3 bg-[#EDEAFF] text-purple-950 font-bold text-sm rounded-md"
              >
                <div class="">View Details</div>
                <i
                  class="pi pi-arrow-right"
                  style="font-size: 0.5rem"
                ></i>
              </div>
            </a>
          </div>
        </a>

        <div v-else>
          <!-- Search Field -->
          <div class="flex justify-start mb-6">
            <input
              v-model="searchTerm"
              type="text"
              placeholder="Search by title"
              class="p-2 border rounded-2xl w-[320px] mb-4  focus:ring-2 focus:ring-primary-purple focus:border-primary-purple outline-none sm:text-sm h-11 block m-2"
            />
          </div>
        
          <div class="flex exo items-center gap-6 mb-6 w-full">
            <!-- Check if there are no courses matching the search term -->
            <div v-if="filteredCourses1.length === 0" class="w-full text-center text-gray-500">
              No courses found
            </div>
        
            <!-- Filtered Courses -->
            <div v-else class="w-full flex exo items-center gap-6 mb-6">
              <div
                v-for="(course, index) in filteredCourses1"
                :key="index"
                class="w-[272px] border hover:scale-105 p-6 bg-white rounded-[18px] shadow-lg flex flex-col items-start justify-center mb-6"
              >
                <div class="w-full flex justify-start items-center gap-4 mb-6">
                  <img
                    class="w-14 h-14 object-cover rounded-full border"
                    :src="course.image"
                    :alt="course.title"
                  />
                  <div class="text-xs">{{ course.duration }} Day{{ +(course.duration)>1?'s':'' }} Course</div>
                  <div class="font-semibold">₦{{ course.amount }}</div>
                </div>
                <div class="w-full text-left">
                  <h3 class="text-[#4b4b4b] text-base font-semibold h-[70px]">{{ course.title }}</h3>
                  <p class="text-[#4b4b4b] text-sm font-light h-[80px] mt-4 mb-10">
                    {{ course.description.substring(0,100) }}...
                  </p>
        
                  <div class="w-full flex justify-between items-center mt-6">
                    <a
                      :href="`/minicourse/${course.id}`"
                      class="w-[123px] h-10 flex justify-center items-center rounded-[48px] border border-[#6b107c] text-center text-[#6b107c] text-sm"
                    >
                      View Details
                    </a>
                    <div>
                      <div class="text-[10px] text-gray-500">Duration</div>
                      <div>{{ course.hours }} hour{{ course.hours=='1'?'':'s' }}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        
        
      </div>


      
      <!-- Skeleton Loader -->
      <div v-else class="w-full">
        <div class="flex items-center gap-6 justify-center flex-wrap">
          <div class="w-[280px]">
              <div class="w-full rounded overflow-hidden shadow-lg animate-pulse">
              <div class="h-48 bg-gray-300"></div>
              <div class="px-6 py-4">
                  <div class="h-6 bg-gray-300 mb-2"></div>
                  <div class="h-4 bg-gray-300 w-2/3"></div>
              </div>
              <div class="px-6 pt-4 pb-2">
                  <div class="h-4 bg-gray-300 w-1/4 mb-2"></div>
                  <div class="h-4 bg-gray-300 w-1/2"></div>
              </div>
              </div>
          </div>
          <div class="w-[280px]">
              <div class="w-full rounded overflow-hidden shadow-lg animate-pulse">
              <div class="h-48 bg-gray-300"></div>
              <div class="px-6 py-4">
                  <div class="h-6 bg-gray-300 mb-2"></div>
                  <div class="h-4 bg-gray-300 w-2/3"></div>
              </div>
              <div class="px-6 pt-4 pb-2">
                  <div class="h-4 bg-gray-300 w-1/4 mb-2"></div>
                  <div class="h-4 bg-gray-300 w-1/2"></div>
              </div>
              </div>
          </div>
          <div class="w-[280px]">
              <div class="w-full rounded overflow-hidden shadow-lg animate-pulse">
              <div class="h-48 bg-gray-300"></div>
              <div class="px-6 py-4">
                  <div class="h-6 bg-gray-300 mb-2"></div>
                  <div class="h-4 bg-gray-300 w-2/3"></div>
              </div>
              <div class="px-6 pt-4 pb-2">
                  <div class="h-4 bg-gray-300 w-1/4 mb-2"></div>
                  <div class="h-4 bg-gray-300 w-1/2"></div>
              </div>
              </div>
          </div>
          <div class="w-[280px]">
              <div class="w-full rounded overflow-hidden shadow-lg animate-pulse">
              <div class="h-48 bg-gray-300"></div>
              <div class="px-6 py-4">
                  <div class="h-6 bg-gray-300 mb-2"></div>
                  <div class="h-4 bg-gray-300 w-2/3"></div>
              </div>
              <div class="px-6 pt-4 pb-2">
                  <div class="h-4 bg-gray-300 w-1/4 mb-2"></div>
                  <div class="h-4 bg-gray-300 w-1/2"></div>
              </div>
              </div>
          </div>
      </div>
        </div>
       
        
    </div>
  </section>
  <FooterSection />
</template>

<script setup lang="ts">
import { ref, computed, onMounted } from "vue";
import Navbar from "../HomeView/Navbar.vue";
import LevelBarIconComp from "../../components/IconComp/LevelBarIconComp.vue";
import FooterSection from "../HomeView/FooterSection.vue";
import { CourseApi } from "@/services";

const selectedCategory = ref("All");
const showDropdown = ref(false);
const isLoading = ref(false);
const courses =ref<Course[]>([]);
const categories=ref<string[]>([''])
  const searchTerm = ref('');
type CourseLevel = "Beginner" | "Intermediate" | "Advanced";

interface Course {
  id: string;
  title: string;
  image: string;
  link: string;
  duration: string;
  level: CourseLevel;
  projects: string;
  rating: string;
  category: string;
}
// Type definition for a single course object
interface MiniCourse {
  title: string;
  description: string;
  image: string;
  duration: string;
  amount: string;
  id: string;
  hours: string;
}

// Computed property to filter courses based on the search term
const filteredCourses1 = computed(() => {
  const searchTermLower = searchTerm.value.toLowerCase();
  return MiniCourses.value.filter((course: MiniCourse) =>
    course.title.toLowerCase().includes(searchTermLower)
  );
})

// function to get all microcourse

async function getAllMiniCourses() {
  try {
    const response = await CourseApi.allMicroCourses() as any;
    MiniCourses.value = response;
  } catch (error) {
    console.error('Error fetching mini courses:', error);
  }
}

onMounted(async () => {
  getAllMiniCourses()
  isLoading.value=true
  try {
    const response = await CourseApi.allCourses() as any; 
    courses.value =  response
   
    // Update courses data with fetched data
  } catch (error) {
    console.error('Error fetching courses:', error);

  }
  finally{
  isLoading.value=false

  }
});

const MiniCourses = ref<MiniCourse[]>([

]);

onMounted(
  async ()=>{

    try{
      const res =await CourseApi.allCategories() as any
      categories.value=res

    }
    catch (error){
      console.error('Error fetching courses:', error);

    }
  }
)


//   {
//     title: "Data Science Foundation: The Python Techniques",
//     image:
//       "https://res.cloudinary.com/dp0whcqar/image/upload/v1719312747/8_ehipfx.png",
//     link: "https://bit.ly/10-weeks-DataScience",
//     duration: "8 weeks",
//     level: "Beginner",
//     projects: "4",
//     rating: "4.6",
//     category: "Data Science",
//   },
//   {
//     title: "Python: Introduction to Python for Beginners",
//     image:
//       "https://res.cloudinary.com/dp0whcqar/image/upload/v1719312744/1_zcoly6.png",
//     link: "https://bit.ly/10-weeks-DataScience",
//     duration: "4 weeks",
//     level: "Intermediate",
//     projects: "4",
//     rating: "4.9",
//     category: "Data Science",
//   },
//   {
//     title: "Django: Build anything Web Application with Python",
//     image:
//       "https://res.cloudinary.com/dp0whcqar/image/upload/v1719312746/7_x9ibol.png",
//     link: "https://bit.ly/10-weeks-DataScience",
//     duration: "4 weeks",
//     level: "Beginner",
//     projects: "4",
//     rating: "4.9",
//     category: "Web Development",
//   },
//   {
//     title: "Figma/Adobe XD: Jump Start Application Designs",
//     image:
//       "https://res.cloudinary.com/dp0whcqar/image/upload/v1719312746/2_xyfsqx.png",
//     link: "https://bit.ly/10-weeks-DataScience",
//     duration: "4 weeks",
//     level: "Advance",
//     projects: "2",
//     rating: "4.9",
//     category: "Design",
//   },
//   {
//     title: "Build Modern Static and Dynamic Web Sites",
//     image:
//       "https://res.cloudinary.com/dp0whcqar/image/upload/v1719312745/6_aauves.png",
//     link: "https://bit.ly/10-weeks-DataScience",
//     duration: "4 weeks",
//     level: "Advance",
//     projects: "2",
//     rating: "4.9",
//     category: "Web Development",
//   },
//   {
//     title: "How To Easily Create Website with Vue",
//     image:
//       "https://res.cloudinary.com/dp0whcqar/image/upload/v1719312750/9_jyz1pk.png",

//     link: "https://bit.ly/10-weeks-DataScience",
//     duration: "4 weeks",
//     level: "Intermediate",
//     projects: "3",
//     rating: "4.9",
//     category: "Web Development",
//   },
//   {
//     title: "Python Automation: Routine Task with Python",
//     image:
//       "https://res.cloudinary.com/dp0whcqar/image/upload/v1719312754/4_zl4ghj.png",

//     link: "https://bit.ly/10-weeks-DataScience",
//     duration: "4 weeks",
//     level: "Beginner",
//     projects: "2",
//     rating: "4.9",
//     category: "Data Science",
//   },
//   {
//     title: "Python for DataBase Experts: Manipulate & Transform Data",
//     image:
//       "https://res.cloudinary.com/dp0whcqar/image/upload/v1719312749/5_rj4kvl.png",

//     link: "https://bit.ly/10-weeks-DataScience",
//     duration: "4 weeks",
//     level: "Beginner",
//     projects: "2",
//     rating: "4.9",
//     category: "Data Science",
//   },
//   {
//     title: "Low Code / No Code Data Science  ",
//     image:
//       "https://res.cloudinary.com/dp0whcqar/image/upload/v1719312751/12_w029gm.png",
//     link: "https://bit.ly/10-weeks-DataScience",
//     duration: "4 weeks",
//     level: "Beginner",
//     projects: "2",
//     rating: "4.9",
//     category: "Gen",
//   },
//   {
//     title: "Generative Artificial Intelligence ",
//     image:
//       "https://res.cloudinary.com/dp0whcqar/image/upload/v1719312748/10_jtio20.png",
//     link: "https://bit.ly/10-weeks-DataScience",
//     duration: "4 weeks",
//     level: "Intermediate",
//     projects: "2",
//     rating: "4.9",
//     category: "Gen",
//   },
//   {
//     title: "Low Code / No Code Machine Learning  ",
//     image:
//       "https://res.cloudinary.com/dp0whcqar/image/upload/v1719312749/11_by3qsl.png",
//     link: "https://bit.ly/10-weeks-DataScience",
//     duration: "4 weeks",
//     level: "Beginner",
//     projects: "2",
//     rating: "4.9",
//     category: "Gen",
//   },
// ]);

const filteredCourses = computed(() => {
  if (selectedCategory.value === "All") {
    showDropdown.value = !showDropdown;
    return courses.value;
  }
  // else if (selectedCategory.value === "Mini") {
  //   showDropdown.value = !showDropdown;
  //   return MiniCourses.value;
  // }
  
  else {
    showDropdown.value = !showDropdown;
    return courses.value.filter(
      (course) => course.category.includes(selectedCategory.value)
    );
  }
});

const filterCourses = (category: string) => {
  selectedCategory.value = category;
  showDropdown.value=false
};





// fetch(`${process.env.VUE_APP_Base_url}/courses/`, {
//   method: 'GET',
//   headers: {
//     'Integration-Token': `${process.env.VUE_APP_INTERGRATION_TOKEN}`,
//     'Access-Control-Allow-Origin': '*',
//     'Content-Type': 'application/json'
//   }
// })
// .then(response => {
//   if (!response.ok) {
//     throw new Error('Network response was not ok');
//   }
//   return response.json();
// })
// .then(data => {
//   console.log('Data:', data);
//   // Process your data here
// })
// .catch(error => {
//   console.error('Error fetching data:', error);
// });

</script>

<style scoped>
.course-item {
  margin: 10px;
  width: 280px !important;
  background: white;
  border-radius: 0.5rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out;
}
.course-item:hover {
  transform: scale(1.05);
}





</style>
