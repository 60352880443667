<template>
  <svg
    width="16"
    height="19"
    viewBox="0 0 16 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.9987 2.58317C12.3019 2.58317 15.7904 6.07162 15.7904 10.3748C15.7904 14.678 12.3019 18.1665 7.9987 18.1665C3.69548 18.1665 0.207031 14.678 0.207031 10.3748C0.207031 6.07162 3.69548 2.58317 7.9987 2.58317ZM7.9987 3.95817C4.45487 3.95817 1.58203 6.83101 1.58203 10.3748C1.58203 13.9187 4.45487 16.7915 7.9987 16.7915C11.5425 16.7915 14.4154 13.9187 14.4154 10.3748C14.4154 6.83101 11.5425 3.95817 7.9987 3.95817ZM7.9987 5.33317C8.34676 5.33317 8.63441 5.59181 8.67996 5.92738L8.6862 6.02067V10.1457C8.6862 10.5254 8.37838 10.8332 7.9987 10.8332C7.65064 10.8332 7.36299 10.5745 7.31743 10.239L7.3112 10.1457V6.02067C7.3112 5.64098 7.61901 5.33317 7.9987 5.33317ZM14.5503 2.6874L14.626 2.74226L15.6879 3.62609C15.9797 3.869 16.0193 4.30249 15.7765 4.59432C15.5538 4.86183 15.171 4.91745 14.884 4.73777L14.8082 4.68291L13.7464 3.79908C13.4545 3.55618 13.4149 3.12268 13.6577 2.83085C13.8805 2.56334 14.2633 2.50772 14.5503 2.6874ZM10.0612 0.291504C10.4409 0.291504 10.7487 0.599311 10.7487 0.979004C10.7487 1.32706 10.49 1.6147 10.1545 1.66022L10.0612 1.6665H5.9362C5.55651 1.6665 5.2487 1.3587 5.2487 0.979004C5.2487 0.630946 5.50733 0.343305 5.84291 0.297783L5.9362 0.291504H10.0612Z"
      fill="#6B107C"
    />
  </svg>
</template>
<script setup></script>
