import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import './main.css'
import VueTypewriterEffect from "vue-typewriter-effect";
import AOS from 'aos';
import 'aos/dist/aos.css';
import '@fortawesome/fontawesome-free/css/all.css';
import PrimeVue from 'primevue/config';
import 'primevue/resources/themes/saga-blue/theme.css';      
import 'primevue/resources/primevue.min.css';                 
import 'primeicons/primeicons.css';  
import Vue3Marquee from 'vue3-marquee'
import { createHead } from '@vueuse/head'


const app = createApp(App);
const head = createHead()
app.use(router);
app.component("vue-typewriter-effect", VueTypewriterEffect)
app.use(PrimeVue);
app.use(Vue3Marquee);
app.use(head)
app.mount('#app')
AOS.init();

