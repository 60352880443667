<template>
  <Navbar />
  
  <!-- <section class="min-h-screen bg-[#F7EFFF] lg:pb-12 pb-6 text-[#5B5B5B]"> -->
  
    
    <!-- Course List Section -->
    <div v-if="!isLoading">
      <section v-for="(course, index) in courses" :key="index" class="text-[#5B5B5B]">
      <div class="flex items-start justify-center min-h-screen bg-[#F7EFFF] lg:pb-12 pb-6 text-[#5B5B5B]">
        <div class="flex lg:flex-row flex-col !justify-between gap-12 items-center lg:mt-[170px] mt-[110px]  max-w-[95vw] sm:max-w-[754px] xl:max-w-[1280px] !w-full !mx-auto lg:px-6 px-2">
          <div class="lg:w-1/2 w-full flex flex-col text-center lg:text-left">
            <h1 class="lg:text-[52px] text-[28px]  uppercase exo font-extrabold text-black">{{ course.title }}</h1>
            <div class="content">{{ course.description }}</div>

            <!-- <div class="mt-8 avenir">2 Sessions Money-back guarantee</div> -->
            <div class="mt-6 flex lg:justify-start justify-center">
              <img src="https://res.cloudinary.com/dp0whcqar/image/upload/v1734016204/Frame_1171276769_orqfhx.png" alt="IACU" class="w-[150px] h-[62px]" />
            </div>
            <!-- <div class="mt-6 flex lg:justify-start justify-center">
              <img src="../../assets/BOOTCAMP (6) 1.png" alt="guarantee" class="w-[106px] h-[84px]" />
            </div> -->

            <div class="lg:flex justify-end items-end hidden">
            <a href="#overview" class="sm:flex hidden border-2 rounded-full z-[1] h-14 w-14 mt-24 justify-center items-center animate-bounce cursor-pointer">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
                <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 13.5 12 21m0 0-7.5-7.5M12 21V3" />
              </svg>    
          </a>
        </div> 


          </div>

          <!-- Course Details Section -->
          <div class="lg:w-1/2 w-full right-0 flex flex-col items-center justify-end" :class="{ 'lg:fixed lg:top-[140px] right-0': isFixed }" data-aos="flip-left" data-aos-easing="ease-out-cubic" data-aos-duration="2000">
            <div class="w-[337px] lg:w-[450px] bg-black rounded-t-lg h-[200px] lg:h-[260px] shadow-md">
              <img :src="course.image" alt="course" class="object-fill rounded-t-lg size-full">
            </div>
            <div class="w-[337px] lg:w-[450px] bg-white rounded-b-lg p-4 shadow-md">
              <div class="w-full flex justify-between items-center">
              <div class="text-[36px] exo font-extrabold text-black"> ₦{{ course.amount }}</div>
              <div class="flex lg:justify-start justify-center">
              <img src="../../assets/BOOTCAMP (6) 1.png" alt="guarantee" class="w-[106px] h-[84px]" />
            </div>
          </div>
              <h2 class="font-black text-black">This course includes:</h2>
              <ul class="pl-3 lg:pl-20">
                <li v-for="(benefit, index) in course.benefits.slice(0, 3)" :key="index" class="text-sm">{{ benefit }}</li>
                <a v-if="course.benefits.length >=3" href="#benefit" class="block underline font-semibold text-sm">See more</a>
              </ul>
              <router-link to="/enroll" class="py-3 block px-20 w-full bg-primary-orange text-primary-purple text-base lg:text-xl mt-4 rounded-[50px] font-extrabold hover:shadow-lg hover:scale-105 text-center">
                Enroll Now
              </router-link>
              <div class="mt-4 avenir text-center text-gray-400 text-sm">2 Sessions Money-back guarantee</div>
            </div>
          </div>
        </div>
      </div>
      

      <!-- Overview Section -->
      <section class="max-w-[95vw] sm:max-w-[754px] xl:max-w-[1280px] !w-full !mx-auto mt-8 lg:px-6 px-2">
        <div class="lg:w-[60%] w-full text-left pr-0 lg:pr-14">
          <h2 id="overview" class="title exo">OVERVIEW</h2>
          <p class="font-bold exo text-2xl">
            Welcome to {{ course.title }}
          </p>

          <p class=" text-[#5B5B5B] text-justify">
            {{ course.overview }}
          </p>

          <h2 id="benefit" class="title exo uppercase">WHO CAN ENROLL </h2>
          <ul class="pl-3 lg:pl-20">
            <li v-for="(audience, index) in course.whoCanEnroll" :key="index">{{ audience }}</li>
          </ul>

          <h2 class="title exo">BENEFITS</h2>
          <ul class="pl-3 lg:pl-20">
            <li v-for="(benefit, index) in course.benefits" :key="index">{{ benefit }}</li>
          </ul>

          <h2 class="title exo">GET CERTIFIED AFTER LEARNING</h2>
          <ul class="pl-3 lg:pl-20">
            <li>Add Verifiable credential to your LinkedIn profile, resume, or CV</li>
            <li>Share it on social media and in your performance review</li>
          </ul>
          <div class="flex items-center justify-center my-3">
            <img src="../../../public/certififcate_data/cert_img/camlds-ds-00018.jpg" alt="" class="w-[180px] h-[230px]">
          </div>

          <h2 class="title exo">DURATION AND FORMAT</h2>
          <p class="font-bold exo lg:text-2xl text-base">
            The duration for learning {{ course.title }} is <span class="text-primary-orange">{{ course.duration }}.</span>
          </p>
          <p>
            At CAMLDS, we train our students in batches to ensure close and well-structured mentorship for each student. This approach allows our instructors to closely monitor your progress and provide exclusive support as you build your projects. At the end of your learning period, you will be tasked with building and presenting your project.
          </p>


        
            <ul class="-mt-3 pl-3 lg:pl-20">
              <li>Online learning</li>
              <li>Physical training at our center or a combination of both.</li>
              <li>Customized training for teams also available</li>
            </ul>
          
 

          <h2 class="title exo">TOOLS COVERED</h2>
          <div class="size-8 flex flex-wrap items-center gap-2 my-8  pb-8 w-full">
              <div v-for="(tool, index) in course.toolsCovered" :key="index" class="text-sm mr-6 !flex justify-start items-center gap-1 px-1.5">

              <img :src="tool.image_url" :alt="`tool ${index+1}`" class="size-8" /> <span>{{tool.name}}</span>
            </div>
          </div>

          <h2 class="title exo uppercase lg:pt-2 md:pt-4 pt-16">WHAT OUR STUDENTS ARE SAYING</h2>
          <Vue3Marquee :pauseOnHover=true :duration="100" >
          <div class="flex flex-nowrap overflow-x-auto mt-[30px] gap-5 items-center">
            <div v-for="(remark, index) in students" :key="index" class="flex-shrink-0 mb-4 border border-[#ECEFF3] rounded-[16px] shadow-md p-6 max-w-[343px] h-fit">
              <div class="flex justify-start gap-3 items-center exo text-lg text-[#0C1118] font-bold mb-5">
                <div :style="{ backgroundColor: colors[index % colors.length] }" class="w-12 h-12 rounded-full flex items-center justify-center text-primary-purple border shadow-md hover:shadow-none">
                  {{ getInitial(remark.name) }}
                </div>
                <div>{{ remark.name }}</div>
                <TickIconComp />
              </div>
              <div class="text-[#454D59] font-normal text-sm exo">
                " {{ remark.comment }} "
              </div>
            </div>
          </div>
          </Vue3Marquee>

          <div class="w-full flex flex-col justify-center lg:justify-start lg:items-start items-center"><h2 class="title exo mt-4">BECOME A STUDENT TODAY</h2>
          <router-link to="/enroll" class="py-3 block px-16 w-fit bg-primary-orange text-primary-purple text-base lg:text-xl mt- lg:my-10 my-6 rounded-[50px] font-extrabold">
            Enroll Now
          </router-link>
        </div>
        </div>
      </section>

    </section>
    </div>

    <div v-if="isLoading && !courses" class="h-screen flex items-center justify-center w-full">
    
        <div class="loader"></div> 

    </div>

    <div v-if="!isLoading && !courses" class="h-screen flex flex-col items-center justify-center w-full">
    
        <div class="">Error Loading Course</div> 
        <button @click="reloadCourses" class="py-3 block px-20 w-fit text-white bg-primary-purple text-base lg:text-xl mt-4 rounded-[50px] font-extrabold hover:shadow-lg hover:scale-105 text-center">
          Try Again
        </button>

    </div>


    <FooterSection />
</template>
<script setup lang="ts">
import Navbar from "../HomeView/Navbar.vue";
import FooterSection from "../HomeView/FooterSection.vue";
import { TickIconComp } from "../../components/IconComp/StrategyIconComp";
import { ref, onMounted, onUnmounted } from "vue";
import AOS from 'aos';
import 'aos/dist/aos.css';
import {  CourseApi } from "@/services";
import { useRoute } from "vue-router";
import { Vue3Marquee } from 'vue3-marquee'

  const route = useRoute();
const courseId = ref<any>(route.params.id);
const courses = ref();
const isLoading = ref(false);


onMounted(async () => {
  try {
    isLoading.value=true
    const response = await CourseApi.singleCourse(courseId.value)
    courses.value = response;
  
    isLoading.value=false
   
  } catch (error) {
    isLoading.value=false
  
    
  }
});

const loadCourses = async () => {
  try {
    isLoading.value=true
    const response = await CourseApi.singleCourse(courseId.value);
    courses.value = response;
    isLoading.value=false
  } catch (error) {
    isLoading.value=false
    console.error('Failed to fetch course:', error);
  }
};

const reloadCourses = async () => {
  await loadCourses();
};



onMounted(() => {
  AOS.init({
    duration: 2000,
  });
});

interface Course {
  title: string;
  image: string;
  description: string;
  overview: string;
  amount: string;
  whoCanEnroll: string[];
  benefits: string[];
  durationAndFormat: {
    duration: string;
    format: string;
  };
  toolsCovered: string[];
}



const students = ref([
    {
      name: "Chukwudi Ogbu",
      image: "https://via.placeholder.com/150",
      comment:
        "Reflecting on my journey with the Centre for Applied Machine Learning and Data Science, I have found it incredibly rewarding, providing invaluable insights into the realm of data science. I wholeheartedly recommend it to those eager to enrich their skill set and advance their IT proficiency in machine learning and data science amidst this rapidly evolving age.",
    },
    {
      name: "Ezekiel Awodi",
      image: "",
      comment:
        "My experience at the Centre for Applied Machine Learning and Data Science was transformative, equipping me with the skills and expertise to succeed in this exciting and rapidly evolving field. I would highly recommend it to anyone looking to advance their skills and knowledge in machine learning and data science.",
    },
    {
      name: "Sunday Meliga",
      image: "",
      comment:
        "With what I’ve seen and experienced so far, also with the students and tutor relationship couples with the relax ambience of the enviroment and then the  state of the art equipments;”CALMDS” is definitely the right place to be.",
    },
    {
      name: "Ben Dominic",
      image: "",
      comment:
        "So far, my experience of learning data science here has been very nice. The facilitators are patient with us and they explain every detail to us in a way we will understand. I recommend for anyone who wishes to learn Data science or Web Development",
    },
  ]);
  const colors = ["#FFDDC1", "#FFD3B4", "#FFF9C4", "#D0F4DE"];
  const getInitial = (name: string) => name.charAt(0).toUpperCase();
  

const isFixed = ref(true);
  
  // Threshold for removing fixed position (94% scroll)
  const scrollThreshold = 0.90;
  
  // Scroll event listener
  const handleScroll = () => {
    const scrollPosition = window.scrollY;
    const windowHeight = window.innerHeight;
    const documentHeight = document.body.clientHeight;
    
    // Calculate the distance scrolled as a percentage of total scrollable height
    const scrollPercentage = (scrollPosition + windowHeight) / documentHeight;
  
    // Update isFixed based on scroll percentage
    isFixed.value = scrollPercentage < scrollThreshold;
  };
  
  // Attach scroll listener when component is mounted
  onMounted(() => {
    window.addEventListener('scroll', handleScroll);
  });
  
  // Remove scroll listener when component is unmounted
  onUnmounted(() => {
    window.removeEventListener('scroll', handleScroll);
  });
  

</script>
<style scoped>

p,
ul {
  padding: 12px 0px;
}
li {
  list-style-type: disc;
  color:#5B5B5B;
}
ul {
  padding-left: 40px;
}

.transition-all {
  transition: all 0.5s ease; /* Adjust timing function and duration as needed */
}


/* HTML: <div class="loader"></div> */
.loader {
  width: 60px;
  aspect-ratio: 1;
  display: flex;
  animation: l8-0 2s infinite sptes(1);
}
.loader::before,
.loader::after {
  content: "";
  flex: 1;
  animation: 
    l8-1 1s infinite linear alternate,
    l8-2 2s infinite steps(1) -.5s;
}
.loader::after {
  --s:-1,-1;
}
@keyframes l8-0 {
  0%  {transform: scaleX(1)  rotate(0deg)}
  50% {transform: scaleX(-1) rotate(-90deg)}
}
@keyframes l8-1 {
  0%,
  5%   {transform:scale(var(--s,1)) translate(0px)   perspective(150px) rotateY(0deg) }
  33%  {transform:scale(var(--s,1)) translate(-10px) perspective(150px) rotateX(0deg) }
  66%  {transform:scale(var(--s,1)) translate(-10px) perspective(150px) rotateX(-180deg)}
  95%,
  100% {transform:scale(var(--s,1)) translate(0px)   perspective(150px) rotateX(-180deg)}
}
@keyframes l8-2 {
  0%  {background:#FEAE49}
  50% {background:#581699}
}
</style>
