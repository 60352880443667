<template>
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M1.55242 0.519963C1.25952 0.22707 0.784649 0.227069 0.491756 0.519963C0.198863 0.812856 0.198863 1.28773 0.491756 1.58062L4.91113 6L0.491756 10.4194C0.198863 10.7123 0.198863 11.1871 0.491756 11.48C0.784649 11.7729 1.25952 11.7729 1.55242 11.48L5.97179 7.06066L10.3913 11.4801C10.6841 11.773 11.159 11.773 11.4519 11.4801C11.7448 11.1872 11.7448 10.7124 11.4519 10.4195L7.03245 6L11.4519 1.58054C11.7448 1.28765 11.7448 0.812776 11.4519 0.519882C11.159 0.226989 10.6841 0.226989 10.3913 0.519882L5.97179 4.93934L1.55242 0.519963Z"
      fill="black"
    />
  </svg>
</template>
<script setup></script>
