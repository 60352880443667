<template>
  <div>
    <!-- Share Button -->
    <button  class="p-2 flex justify-center items-center border shadow-md rounded-full bg-gray size-8" @click="showModal = true">
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-4">
        <path stroke-linecap="round" stroke-linejoin="round" d="M7.217 10.907a2.25 2.25 0 1 0 0 2.186m0-2.186c.18.324.283.696.283 1.093s-.103.77-.283 1.093m0-2.186 9.566-5.314m-9.566 7.5 9.566 5.314m0 0a2.25 2.25 0 1 0 3.935 2.186 2.25 2.25 0 0 0-3.935-2.186Zm0-12.814a2.25 2.25 0 1 0 3.933-2.185 2.25 2.25 0 0 0-3.933 2.185Z" />
      </svg>
      
    </button>

    <!-- Modal Popup -->
    <div v-if="showModal" class="modal-overlay" @click="closeModal">
      <div class="modal-content" @click.stop>
        <div class="flex pb-6 justify-between items-center gap-4">
          <h3 class=" text-base text-gray-800">Share this post</h3>
        
        <!-- Close Modal -->
        <button @click="closeModal"><svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"></path>
        </svg></button>
        </div>
          <!-- Post Title with Watermark -->
          <div class="watermarked-title-container">
            <h4 class="py-6 shadow rounded-lg bg-gray-50 text-lg text-gray-500">{{ blogTitle }}</h4>
          </div>

        <!-- Social Media Share Buttons -->
        <div class="grid md:grid-cols-2 lg:grid-cols-4 grid-cols-2 justify-items-center gap-0 mt-6 space-y-4 sm:space-y-0">
          <button class="p-4 border shadow rounded-full bg-gray size-[52px] hover:scale-105" @click="share('facebook')">
            <i class="fa-brands fa-facebook-f"></i>
          </button>
          <button class="p-4 border shadow rounded-full bg-gray size-[52px] hover:scale-105" @click="share('twitter')">
            <i class="pi pi-twitter"></i>
          </button>
          <button class="p-4 border shadow rounded-full bg-gray size-[52px] hover:scale-105" @click="share('linkedin')">
            <i class="pi pi-linkedin"></i>
          </button>
          <button class="p-4 border shadow rounded-full bg-gray size-[52px] hover:scale-105" @click="share('whatsapp')">
            <i class="pi pi-whatsapp"></i>
          </button>
        </div>

        <div className="flex justify-center items-center my-6 relative">
                      <div className="w-full h-[1px] bg-gray-200"></div>
                      <p className="text-gray-500 text-xs mx-auto absolute left-1/2 transform -translate-x-1/2 bg-white p-1.5">
                        Or
                      </p>
                    </div>        


                    <div className="relative bg-white rounded-[30px] border-[#E6E5E5] border py-2 pl-2 h-12 flex justify-between items-center">
                      <input
                        type="text"
                        readOnly
                        :value="pageLink"
                        className="w-full h-12 bg-transparent border-none outline-none text-sm pl-3 text-gray-700"
                      />
                      <button
                        className="ml-2 bg-[#F1EBFF] text-white px-2 py-[14px] text-xs rounded-r-[30px] w-[50px] h-12 hover:scale-105 absolute top-0 right-0 flex justify-center items-center"
                        @click="copyLink"
                      >
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M16.0588 8.25C17.4264 8.24998 18.5287 8.24996 19.3957 8.36652C20.2958 8.48754 21.0536 8.74643 21.6556 9.34835C22.2575 9.95027 22.5164 10.7081 22.6374 11.6083C22.7539 12.4752 22.7539 13.5775 22.7539 14.9451V14.9451V16.0549V16.0549C22.7539 17.4225 22.7539 18.5248 22.6374 19.3918C22.5164 20.2919 22.2575 21.0497 21.6556 21.6517C21.0536 22.2536 20.2958 22.5125 19.3957 22.6335C18.5287 22.75 17.4264 22.75 16.0588 22.75H16.0588H14.949H14.949C13.5814 22.75 12.4791 22.75 11.6122 22.6335C10.712 22.5125 9.95417 22.2536 9.35226 21.6516C8.75034 21.0497 8.49145 20.2919 8.37043 19.3918C8.25387 18.5248 8.25389 17.4225 8.25391 16.0549V16.0549V14.9451V14.9451C8.25389 13.5775 8.25387 12.4752 8.37043 11.6082C8.49145 10.7081 8.75034 9.95027 9.35226 9.34835C9.95417 8.74643 10.712 8.48754 11.6122 8.36652C12.4791 8.24996 13.5814 8.24998 14.949 8.25H14.949H16.0588H16.0588Z"
                            fill="#8B52FF"
                          />
                          <path
                            d="M6.75 14.8569C6.74991 13.5627 6.74983 12.3758 6.8799 11.4084C7.0232 10.3425 7.36034 9.21504 8.28769 8.28769C9.21504 7.36034 10.3425 7.0232 11.4084 6.8799C12.3758 6.74983 13.5627 6.74991 14.8569 6.75L17.0931 6.75C17.3891 6.75 17.5371 6.75 17.6261 6.65419C17.7151 6.55838 17.7045 6.4142 17.6833 6.12584C17.6648 5.87546 17.6413 5.63892 17.6111 5.41544C17.4818 4.45589 17.2232 3.6585 16.6718 2.98663C16.4744 2.74612 16.2539 2.52558 16.0134 2.3282C15.3044 1.74638 14.4557 1.49055 13.4248 1.36868C12.4205 1.24998 11.1512 1.24999 9.54893 1.25H9.45109C7.84883 1.24999 6.57947 1.24998 5.57525 1.36868C4.54428 1.49054 3.69558 1.74638 2.98663 2.3282C2.74612 2.52558 2.52558 2.74612 2.3282 2.98663C1.74638 3.69558 1.49055 4.54428 1.36868 5.57525C1.24998 6.57947 1.24999 7.84882 1.25 9.45108V9.54891C1.24999 11.1512 1.24998 12.4205 1.36868 13.4247C1.49054 14.4557 1.74638 15.3044 2.3282 16.0134C2.52558 16.2539 2.74612 16.4744 2.98663 16.6718C3.6585 17.2232 4.45589 17.4818 5.41544 17.6111C5.63892 17.6412 5.87546 17.6648 6.12584 17.6833C6.4142 17.7045 6.55838 17.7151 6.65419 17.6261C6.75 17.5371 6.75 17.3891 6.75 17.0931V14.8569Z"
                            fill="#8B52FF"
                          />
                        </svg>
                      </button>
                    </div>



      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue'
import Swal from "sweetalert2";

// Props for the blog title, description, and page link
const props = defineProps({
  blogTitle: {
    type: String,
    required: true
  },
  blogDescription: {
    type: String,
    required: true
  },
  pageLink: {
    type: String,
    required: true
  }
})

// Modal visibility state
const showModal = ref(false)

// Method to close the modal
const closeModal = () => {
  showModal.value = false
}

// Method to handle social media sharing
const share = (platform) => {
  let url = ''
  const encodedTitle = encodeURIComponent(props.blogTitle)
  const encodedDescription = encodeURIComponent(props.blogDescription)
  const encodedUrl = encodeURIComponent(props.pageLink)
  const twitterText= `${encodedTitle}  
  ${limitSentence(encodedDescription, 20)} `


  function limitSentence(sentence, wordLimit) {
  // Split the sentence into an array of words
  const words = sentence.split(' ');
  
  // Slice the array to the desired word limit
  const limitedWords = words.slice(0, wordLimit);
  
  // Join the words back into a sentence
  return limitedWords.join(' ');
}

  switch (platform) {
    case 'facebook':
  url = `https://www.facebook.com/sharer/sharer.php?u=${props.pageLink}`;
  break;

    case 'twitter':
      url = `https://twitter.com/intent/tweet?text=${twitterText}&url=${encodedUrl}`
      break
      case 'linkedin':
  url = `https://www.linkedin.com/sharing/share-offsite/?url=${encodedUrl}`;
  break;

    case 'whatsapp':
    url = `https://wa.me/?text=${encodeURIComponent(props.blogTitle)}%0A%0A${encodeURIComponent(props.blogDescription)}%20...%20%0A%0ARead%20more%20=>${encodeURIComponent(props.pageLink)}`
      break
    default:
      break
  }
  window.open(url, '_blank')
}

// Method to copy the link to the clipboard
const copyLink = () => {
  navigator.clipboard.writeText(props.pageLink)
  const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.onmouseenter = Swal.stopTimer;
              toast.onmouseleave = Swal.resumeTimer;
            },
          });
          Toast.fire({
            icon: "success",  
            title: "Link copied to clipboard! ",
          });
}
</script>

<style scoped>
/* Modal Overlay */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Modal Content */
.modal-content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  width:100%;
  margin: 0 12px;
  max-width: 448px;
  text-align: center;
}


.watermarked-title-container {
  position: relative;
  display: inline-block;
}

h4 {
  position: relative;
  font-size: 1.5rem;
  color: #4B4B4B;
}

h4::before {
  content: "";
  background-image: url('../assets/camlds-logo.png');
  background-size: contain;
  background-repeat: no-repeat;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0.1; /* Adjust opacity for watermark effect */
  z-index: 1; /* Ensures the watermark stays behind the text */
  width: 100%;
  height: 100%;
}



</style>
