<template>
  <Navbar />

  <div v-if="!isLoading && microCourses" v-for="(course, index) in microCourses" :key="index" >
  <div
    class="flex items-start justify-center min-h-screen bg-[#F7EFFF] lg:pb-12 pb-6 text-[#5B5B5B] overflow-none"
  >
    <div
      class="flex lg:flex-row flex-col !justify-between gap-12 items-center lg:mt-[170px] mt-[110px] max-w-[95vw] sm:max-w-[754px] xl:max-w-[1280px] !w-full !mx-auto lg:px-6 px-2"
    >
      <div class="lg:w-[60%] w-full flex flex-col text-center lg:text-left">
        <h1
          class="lg:text-[40px] text-[28px] uppercase exo font-extrabold text-black"
        >
          {{course.title}}
        </h1>
        <div class="content lg:w-[77%] w-full text-justify mt-2">
         {{ course.description }}
        </div>

        <!-- <div class="mt-8 avenir">2 Sessions Money-back guarantee</div> -->
        <!-- <div class="mt-6 flex lg:justify-start justify-center">
              <img src="https://res.cloudinary.com/dp0whcqar/image/upload/v1734016204/Frame_1171276769_orqfhx.png" alt="IACU" class="w-[150px] h-[62px]" />
            </div> -->


        <div class="ms:mt-20 mt-10 grid grid-cols-1 md:grid-cols-3 lg:grid-cols-3  bg-white sm:w-[740px] w-full sm:rounded-2xl rounded-none sm:shadow-[0px_4px_2.6px_3px_rgba(41,45,50,0.20)] shaodow-none">
            <div class="relative mb-3 px-3 lg:mb-0 sm:p-6 sm:py-10 py-2 p-2 text-left">
              
              <h5 class="font-bold text-primary"> Beginner level</h5>
              <h6 class="mb-0 font-normal dark:text-neutral-50 flex items-center gap-4">Prerequisites  <span class="cursor-pointer" @click="openInfoModal"><InfoIconComp /></span></h6>
              <div
                class="absolute right-0 top-0 hidden h-full min-h-[1em] w-px self-stretch border-t-0 bg-gradient-to-tr from-transparent via-neutral-500 to-transparent opacity-25 dark:via-neutral-400 lg:block"></div>
            </div>
            <div class="relative mb-3 px-3 lg:mb-0 sm:p-6 sm:py-10 py-2 p-2 text-left">
              
              <h5 class="font-bold text-primary flex items-center gap-4">  {{ course.duration }} day{{course.duration>1?'s':''}} <span class="font-normal">({{course.hours}} hour{{course.hours>1?'s':''}} {{course.duration>1?'daily':''}})</span> <span><TimeIconComp /></span></h5>
              <h6 class="mb-0 font-normal dark:text-neutral-50">Duration</h6>
              <div
                class="absolute right-0 top-0 hidden h-full min-h-[1em] w-px self-stretch border-t-0 bg-gradient-to-tr from-transparent via-neutral-500 to-transparent opacity-25 dark:via-neutral-400 lg:block"></div>
            </div>
            <div class="relative mb-3 px-3 lg:mb-0 sm:p-6 sm:py-10 py-2 p-2 text-left">
              
              <h5 class="font-bold text-primary">Hands-on-learning</h5>
              <h6 class="mb-0 font-normal dark:text-neutral-50">Expert-led skill mastery</h6>
                 </div>
           
                  <!-- Info Modal -->
          <InfoModal :isOpen="isInfoModalOpen" @update:isOpen="isInfoModalOpen = $event" :writeUp="course.prerequisites" />
          </div>

        </div>
       

      <!-- Course Details Section -->
      <div
        class="lg:w-1/2 w-full right-0 flex flex-col items-center justify-end"
        :class="{ 'lg:fixed lg:top-[140px] right-0': isFixed }"
        data-aos="flip-left"
        data-aos-easing="ease-out-cubic"
        data-aos-duration="2000"
      >
        <div
          class="w-[337px] lg:w-[377px] bg-black rounded-t-lg h-[200px] lg:h-[170px] shadow-md"
        >
          <img
            :src="course.image"
            alt="course"
            class="object-cover rounded-t-lg size-full"
          />
        </div>
        <div class="w-[337px] lg:w-[377px] bg-white rounded-b-lg p-4 shadow-md">
          <div class="w-full flex justify-between items-center">
            <div class="text-[36px] exo font-extrabold text-black">₦{{course.amount}}</div>
            <div class="flex lg:justify-start justify-center">
              <img
                src="../../assets/BOOTCAMP (6) 1.png"
                alt="guarantee"
                class="w-[80px] h-[62px]"
              />
            </div>
          </div>
          <h2 class="font-black text-black">Benefits:</h2>
          <ul class="pl-3 list-none">
            <li v-for="(benefit,index) in extractListItems(course.benefits)" :key="index" class="checkmark-item">
              <span class="size-5 mt-1"><ListIconComp /></span>
               <span class="text-left">{{ benefit.trim() }}</span>
              </li>
             
          </ul>
          <router-link
          :to="{ path: '/enroll', query: { key: course.title} }"
            class="py-2 block px-20 w-full bg-primary-orange text-primary-purple text-base lg:text-xl mt-4 rounded-[50px] font-extrabold hover:shadow-lg hover:scale-105 text-center"
          >
            Enroll Now
          </router-link>
          <!-- <div class="mt-4 avenir text-center text-gray-400 text-sm">
            2 Sessions Money-back guarantee
          </div> -->
        </div>
      </div>
    </div>
    
  </div>
  <div class="lg:flex justify-center items-center hidden w-full -mt-10">
    <a
      href="#overview"
      class="sm:flex hidden border-2 rounded-full z-[1] h-14 w-14 justify-center items-center animate-bounce cursor-pointer"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke-width="1.5"
        stroke="currentColor"
        class="size-6"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          d="M19.5 13.5 12 21m0 0-7.5-7.5M12 21V3"
        />
      </svg>
    </a>
  </div>

  <div
    class=" max-w-[95vw] sm:max-w-[754px] xl:max-w-[1280px] !w-full !mx-auto lg:px-6 px-2 mt-8"
  >
    <!-- Learn step by step Section (Checklist) -->
    <div class="w-full" id="overview">
      <h2 class=" text-[#3a0444] text-[32px] font-bold title">
        Learn step by step
      </h2>
      
      <div class="flex-col flex lg:w-[60%] w-full xl:gap-y-[12px] gap-y-[28px] mt-8 mb-20">
        <section v-for="(item, i) in extractQuestionsFromHTML(course.what_you_will_learn)">
          <div class="bg-white px-0 sm:px-[15px] xl:px-5">
            <div
              @click="handleDropDown(i)"
              class="flex border-b border-border-grey cursor-pointer text-text-black xl:text-xl justify-between items-center min-h-[44px] xl:min-h-[60px] w-full"
            >
              <h1 class="max-w-[80%] w-full">{{ item.question }}</h1>
              <TimesIconComp
                class="size-2.5"
                v-if="currentQuestionOpen && currentQuestion === i"
              />
              <AddIconComp v-else class="w-[14px] h-[14px] xl:w-[24px] xl:h-[24px]" />
            </div>
            <div
              v-if="currentQuestionOpen && currentQuestion === i"
              class="min-h-[80px] pl-4 xl:min-h-[60px] border-b border-border-grey py-2 text-gray-500 bg-gray-50 text-base "
            >

              <!-- create a ul tag -->
              <ul class="list-disc pl-5 space-y-3">
                <li v-for="(subItem, j) in item.answer" :key="j"  v-html="subItem"></li>
              </ul>
            
            </div>
          </div>
        </section>
      </div>
    </div>

    <!-- Projects Section -->
    <div class="sm:w-[65%] w-full">
        <h2 class="text-[#3a0444] text-[32px] font-bold title">Projects</h2>
        <div class="flex items-center gap-4 my-10 flex-wrap">
          <!-- Gallery images -->
          <div
            v-for="(image, index) in course.projects"
            :key="index"
            class="relative w-[140px] sm:h-[150px] h-[100px] group rounded-xl shadow"
          >
            <img
              :src="image.image"
              :alt="image.name"
              class="w-full h-full object-cover cursor-pointer transition-transform duration-300 rounded-xl ease-in-out transform group-hover:scale-105"
              @click="openModal(image)"
            />
    
            <!-- Icon to indicate image can be enlarged -->
            <div
            @click="openModal(image)"
              class="absolute bottom-4 right-4 opacity-0 cursor-pointer group-hover:opacity-100 bg-gray-300 p-2 rounded-full transition-opacity duration-300"
            >
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="gray" class="size-6">
                <path stroke-linecap="round" stroke-linejoin="round" d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607ZM10.5 7.5v6m3-3h-6" />
              </svg>
              
            </div>
          </div>
    
          <!-- Modal -->
          <div
            v-if="isModalOpen"
            class="fixed inset-0 bg-black bg-opacity-75 flex justify-center items-center z-50"
            @click="closeModal"
          >
            <div class="relative" @click.stop>
              <!-- Close Button -->
              <button
                class="absolute top-4 right-4 bg-white text-black rounded-full p-2 shadow-lg hover:bg-gray-200 transition"
                @click="closeModal"
              >
                <svg
                  class="w-6 h-6"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
    
              <!-- Image -->
              <img
                :src="currentImage?.image"
                :alt="currentImage?.name"
                class="max-w-full max-h-screen object-contain"
              />
            </div>
          </div>
        </div>
      </div>

      <!-- Suggestion -->
      <div class="sm:w-[65%] w-full" v-if="miniCourses.length > 0">
        <h2 class="title font-semibold mb-4">You may also like</h2>
        <!-- <div class="space-y-4">
          <div 
            v-for="course in miniCourses.slice(0, 4)" 
            :key="course?.id" 
            class="bg-gray-100 p-4 rounded-lg shadow-sm hover:shadow-md border cursor-pointer"
          >
            <router-link :to="`/minicourse/${course?.id}`">
              <h3 class="text-lg font-medium text-gray-900">{{ course?.title }}</h3>
            </router-link>
          </div>
        </div> -->
<div class="w-full flex items-center gap-4 flex-wrap ">
        <div
        v-for="(course, index) in miniCourses.slice(0, 4)"
        :key="index"
        class="w-[272px] border hover:scale-105 p-6 bg-white rounded-[18px] shadow-lg flex flex-col items-start justify-center my-6"
      >
        <div class="w-full flex justify-start items-center gap-4 mb-6">
          <img
            class="w-14 h-14 object-cover rounded-full border"
            :src="course.image"
            :alt="course.title"
          />
          <div class="text-xs">{{ course.duration }} Day{{ +(course.duration)>1?'s':'' }} Course</div>
          <div class="font-semibold">₦{{ course.amount }}</div>
        </div>
        <div class="w-full text-left">
          <h3 class="text-[#4b4b4b] text-base font-semibold h-[70px]">{{ course.title }}</h3>

          <div class="w-full flex justify-between items-center mt-6">
            <a
              :href="`/minicourse/${course.id}`"
              class="w-[123px] h-10 flex justify-center items-center rounded-[48px] border border-[#6b107c] text-center text-[#6b107c] text-sm"
            >
              View Details
            </a>
            <div>
              <div class="text-[10px] text-gray-500">Duration</div>
              <div>{{ course.hours }} hour{{ course.hours=='1'?'':'s' }}</div>
            </div>
          </div>
        </div>
      </div>
      </div>
      </div>
      <!-- <div class="flex flex-col space-y-4 md:space-y-0 md:space-x-6 md:flex-row sm:w-[65%] w-full">
				<img src="https://source.unsplash.com/75x75/?portrait" alt="" class="self-center flex-shrink-0 w-24 h-24 border rounded-full md:justify-self-start dark:bg-gray-500 dark:border-gray-300">
				<div class="flex flex-col">
					<h4 class="text-lg font-semibold">Leroy Jenkins</h4>
					<p class="dark:text-gray-600">Sed non nibh iaculis, posuere diam vitae, consectetur neque. Integer velit ligula, semper sed nisl in, cursus commodo elit. Pellentesque sit amet mi luctus ligula euismod lobortis ultricies et nibh.</p>
				</div>
			</div>     -->
  
  
  
  
      <!-- Enroll Now Button -->
    <div class="w-full my-8">
      <router-link
      :to="{ path: '/enroll', query: { key: course.title} }"
        class="px-8 py-4 bg-[#feae49] rounded-[50px] flex justify-center items-center gap-2.5 w-fit"
      >
        <span class="text-center text-[#581699] text-xl">Enroll Now</span>
    </router-link>
    </div>
  </div>
  </div>
  <div v-if="isLoading && !microCourses" class="h-screen flex items-center justify-center w-full">
    
    <div class="loader animate-pulse sm:text-2xl text-lg"></div> 

</div>

<div v-if="!isLoading && !microCourses" class="h-screen flex flex-col items-center justify-center w-full">

    <div class="">Error Loading Course</div> 
    <button @click="reloadCourses" class="py-3 block px-20 w-fit text-white bg-primary-purple text-base lg:text-xl mt-4 rounded-[50px] font-extrabold hover:shadow-lg hover:scale-105 text-center">
      Try Again
    </button>

</div>

  <FooterSection />
</template>
<script setup lang="ts">
import Navbar from "../HomeView/Navbar.vue";
import FooterSection from "../HomeView/FooterSection.vue";
import InfoIconComp from "../../components/IconComp/InfoIconComp.vue";
import AddIconComp from "../../components/IconComp/AddIconComp.vue";
import TimeIconComp from "../../components/IconComp/TimeIconComp.vue";
import ListIconComp from "../../components/IconComp/ListIconComp.vue";
import InfoModal from  "../../components/ModalComp/InfoModal.vue"
import TimesIconComp from "../../components/IconComp/TimesIconComp.vue";
import { ref, onMounted, onUnmounted } from "vue";
import AOS from "aos";
import "aos/dist/aos.css";
import { useRoute } from "vue-router";
import { CourseApi } from "@/services";
import {watch} from 'vue'


interface MiniCourse {
  title: string;
  description: string;
  image: string;
  duration: string;
  amount: string;
  id: string;
  hours: string;
}

const route = useRoute();
const courseId = ref<any>(route.params.id);
const isLoading = ref<Boolean>(false);
const miniCourses = ref<MiniCourse[]>([])
    const microCourses = ref();

const loadCourses = async (id:any) => {
  isLoading.value=true
  
  try {
    const response = await CourseApi.singleMicroCourse(id);
    microCourses.value=response
    // console.log(response)
    isLoading.value=false
  } catch (error) {
    isLoading.value=false
    console.error('Failed to fetch micro course:', error);
  }
}


const reloadCourses = async () => {
  await loadCourses(route.params.id);
};

watch(() =>route.params.id, async() => {
  await loadCourses(route.params.id);
  getAllMiniCourses(route.params.id);
});

function extractListItems(htmlString: string): string[] {
    // Create a temporary DOM element to parse the HTML string
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = htmlString;

    // Select all li elements and map their text content into an array
    const listItems: string[] = Array.from(tempDiv.querySelectorAll('li')).map(
        (li: HTMLLIElement) => li.textContent?.trim() || ''
    );

    return listItems;
}




// Fetch courses
const getAllMiniCourses = async (id:any) => {
  if(courseId){
  try {
    const response = await CourseApi.allMicroCourses() as any; // Ensure courseId is a string
      miniCourses.value = response.filter((item: any) => {
        // Make sure item.id is a string too for correct comparison
        return (item.id) !== id;
      });
  } catch (error) {
    console.error('Error fetching mini courses:', error);
  }}
};

// Fetch on mounted
onMounted(() => {
  getAllMiniCourses(route.params.id);
});

type Question = {
  question: string;
  answer: string[];
};

function extractQuestionsFromHTML(html: string): Question[] {
  const parser = new DOMParser();
  const doc = parser.parseFromString(html, "text/html");
  const ulElements = doc.querySelectorAll("ul > li");

  const questions: Question[] = Array.from(ulElements).map((liElement) => {
    const question = liElement.firstChild?.textContent?.trim() || "No question provided";
    const nestedUl = liElement.querySelector("ul");

    const answer: string[] = [];
    if (nestedUl) {
      const nestedLi = nestedUl.querySelectorAll("li");
      nestedLi.forEach((li) => {
        answer.push(li.outerHTML.trim());
      });
    }

    return { question, answer };
  });

  return questions.filter((q) => q.question !== "No question provided" && q.answer.length > 0);
}

// State to control the modal visibility
const isInfoModalOpen = ref(false);

// Method to open the modal
const openInfoModal = () => {
  isInfoModalOpen.value = true;
};

type question = {
  question: string;
  answer: string;
};

const currentQuestion = ref<number>();
const currentQuestionOpen = ref<boolean>(false);



const handleDropDown = (index: number) => {
  if (index == currentQuestion.value) {
    currentQuestionOpen.value = !currentQuestionOpen.value;
  } else {
    currentQuestion.value = index;
    currentQuestionOpen.value = true;
  }
};

const isFixed = ref(true);
onMounted(() => {
    loadCourses(route.params.id)
  AOS.init({
    duration: 2000,
  });
});
// Threshold for removing fixed position (90% scroll)
const scrollThreshold = 0.89;

// Scroll event listener
const handleScroll = () => {
  const scrollPosition = window.scrollY;
  const windowHeight = window.innerHeight;
  const documentHeight = document.body.clientHeight;

  // Calculate scroll percentage
  const scrollPercentage = (scrollPosition + windowHeight) / documentHeight;

  // Get the position of the footer
  const footer = document.querySelector("footer");
  const footerPosition = footer ? footer.offsetTop : documentHeight;

  // Check if the fixed card should remain fixed or not
  if (scrollPosition + windowHeight >= footerPosition) {
    // If we're near the footer, unfix the card
    isFixed.value = false;
  } else {
    // Otherwise, keep it fixed
    isFixed.value = scrollPercentage < scrollThreshold;
  }
};

// Attach scroll listener when component is mounted
onMounted(() => {
  window.addEventListener("scroll", handleScroll);
});

// Remove scroll listener when component is unmounted
onUnmounted(() => {
  window.removeEventListener("scroll", handleScroll);
});

// Define types for the images
interface Image {
  name: string;
  image: string;
 
}

// Image data
// const images: Image[] = [
//   {
//     title: "Project 1",
//     imageLink:"https://res.cloudinary.com/dp0whcqar/image/upload/v1719312746/2_xyfsqx.png",},
  
//   {
//     title: "Project 2",
//     imageLink: "https://res.cloudinary.com/dp0whcqar/image/upload/v1734016204/Frame_1171276769_orqfhx.png",},
  
//   {
//     title: "Project 3",
//     imageLink:
//       "https://res.cloudinary.com/dp0whcqar/image/upload/v1719312746/2_xyfsqx.png",},
   
//   {
//     title: "Project 4",
//     imageLink:
//       "https://res.cloudinary.com/dp0whcqar/image/upload/v1719312746/2_xyfsqx.png",},
   
// ];

// Modal state
const isModalOpen = ref(false);
const currentImage = ref<Image | null>(null);

// Open modal with selected image
function openModal(image: Image): void {
  currentImage.value = image;
  isModalOpen.value = true;
}

// Close modal
function closeModal(): void {
  isModalOpen.value = false;
  currentImage.value = null;
}
</script>
<style scoped>

.loader {
  width: 60px;
  aspect-ratio: 1;
  display: flex;
  animation: l8-0 2s infinite sptes(1);
}
.loader::before,
.loader::after {
  content: "";
  flex: 1;
  animation: 
    l8-1 1s infinite linear alternate,
    l8-2 2s infinite steps(1) -.5s;
}
.loader::after {
  --s:-1,-1;
}
@keyframes l8-0 {
  0%  {transform: scaleX(1)  rotate(0deg)}
  50% {transform: scaleX(-1) rotate(-90deg)}
}
@keyframes l8-1 {
  0%,
  5%   {transform:scale(var(--s,1)) translate(0px)   perspective(150px) rotateY(0deg) }
  33%  {transform:scale(var(--s,1)) translate(-10px) perspective(150px) rotateX(0deg) }
  66%  {transform:scale(var(--s,1)) translate(-10px) perspective(150px) rotateX(-180deg)}
  95%,
  100% {transform:scale(var(--s,1)) translate(0px)   perspective(150px) rotateX(-180deg)}
}
@keyframes l8-2 {
  0%  {background:#FEAE49}
  50% {background:#581699}
}



</style>
