<template>
    <svg
      width="6"
      height="6"
      viewBox="0 0 6 6"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.36816 3.02734H3.87296"
        stroke="#222222"
        stroke-width="0.469649"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M2.62109 4.27921V1.77441"
        stroke="#222222"
        stroke-width="0.469649"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  </template>
  <script setup></script>
  