<template>
  <section class="py-16 mx-auto flex flex-col items-center justify-center max-w-[95vw] sm:max-w-[754px] xl:max-w-[1280px]">
    <h2 class="text-[#3B0545] title text-center mb-4">C<span class="text-primary-orange">A</span>MLDS in Action</h2>

    <div class="carousel-container relative overflow-hidden bg-white">
      <div class="carousel-slide flex" :style="{ transform: `translateX(-${counter * 100}%)` }">
        <img src="../../assets/gallery/gallery (4).png" alt="Image 1" class="w-full">
        <img src="../../assets/gallery/gallery (3).png" alt="Image 2" class="w-full">
        <img src="../../assets/gallery/gallery (1).png" alt="Image 3" class="w-full">
        <img src="../../assets/gallery/gallery (2).png" alt="Image 4" class="w-full">
        <img src="../../assets/gallery/gallery (5).png" alt="Image 5" class="w-full">
        <img src="../../assets/gallery/gallery (6).png" alt="Image 6" class="w-full">
        <img src="../../assets/gallery/gallery (7).jpg" alt="Image 7" class="w-full h-[393px]">
        <img src="../../assets/gallery/gallery (8).jpg" alt="Image 8" class="w-full  h-[393px]">
        <img src="../../assets/gallery/gallery (9).jpg" alt="Image 9" class="w-full  h-[393px]">
      </div>
      <button class="prev-btn absolute top-1/2 transform -translate-y-1/2 left-0 h-10 w-10 bg-black text-white px-3 py-1 rounded-full opacity-50 hover:opacity-100 transition-opacity duration-300" @click="prevSlide"><i class="pi pi-chevron-left"></i></button>
      <button class="next-btn absolute top-1/2 transform -translate-y-1/2 right-0 h-10 w-10 bg-black text-white px-3 py-1 rounded-full opacity-50 hover:opacity-100 transition-opacity duration-300" @click="nextSlide"><i class="pi pi-chevron-right"></i></button>
    </div>
  </section>
</template>

<script setup>
import { ref } from 'vue';

const counter = ref(0);

const nextSlide = () => {
  if (counter.value < 5 && counter.value !=9) { // Change '5' to the total number of images minus 1
    counter.value++;
  }
};

const prevSlide = () => {
  if (counter.value > 0) {
    counter.value--;
  }
};
</script>

<style scoped>
img {
  transition: opacity 0.9s;
  background-color: black;
}

img:hover {
  opacity: 0.8; 
}
</style>
